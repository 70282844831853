<template>
	<div class="w-full timelinePage">
		<div class="mt-3" style="padding: 50px;">
			<div v-for="(date, dateI) in createDates" :key="date + dateI">
				<h5 class="mb-4">{{ textDate(date.date) }}</h5>
				<a-timeline>
					<div class="dF" v-for="(d, i) in date.sorted" :key="d + i">
						<div class="mr-3" style="margin-top: -5px; color: #9EA0A5;">
							{{ textTime(d) }}
						</div>
						<a-timeline-item>{{ d.logNote }}</a-timeline-item>
					</div>
				</a-timeline>
			</div>
		</div>
	</div>
</template>

<script>

export default {
	computed: {
		events() {
			return this.$store.state.appData.currentTransaction?.timelines?.map(e => this.getLog(e, this.$store.state.user?.user)) || [];
		},
		createDates() {
			let dates = {}
			this.events.forEach(x => {
				let xDate = new Date(x.date)
				let month = xDate.getMonth() + 1
				let day = xDate.getDate()
				let year = xDate.getFullYear()
				if (!dates.hasOwnProperty(`${month}` + '/' + `${day}` + `/` + `${year}`)) {
					let obj = {
						date: `${month}` + '/' + `${day}` + `/` + `${year}`,
						sorted: this.sortDates(`${month}` + '/' + `${day}` + `/` + `${year}`)
					}
					dates[obj.date] = obj
				}
			})
			let sorted = Object.values(dates).sort((a, b) => {
				let { date: date1 } = a
				let { date: date2 } = b

				date1 = new Date(date1).getTime()
				date2 = new Date(date2).getTime()

				return date1 > date2 ? -1 : 1
			})

			return sorted
		},
		transactions() {
			return Object.values(this.$store.state.appData.transactions)
		},
		instance() {
			return this.$store.state.instance
		},
	},
	methods: {
		textTime(date) {
			let d = new Date(date.date)
			let minutes = d.getMinutes().toString()
			if (minutes.length < 2) {
				minutes = '0' + minutes
			}
			return d.getHours() + ':' + minutes
		},
		textDate(date) {
			const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
			let newDate = new Date(date)
			let month = monthNames[Number(newDate.getMonth())]
			return month + ' ' + newDate.getDate() + ', ' + newDate.getFullYear()
		},
		sortDates(date) {
			let dates = []
			this.events.forEach(x => {
				let xDate = new Date(x.date)
				let month = xDate.getMonth() + 1
				let day = xDate.getDate()
				let year = xDate.getFullYear()
				if (date == (`${month}` + '/' + `${day}` + `/` + `${year}`)) {
					dates.push(x)
				}
			})
			dates.sort((a, b) => {
				return new Date(b.date) - new Date(a.date)
			})
			return dates
		},

		getLog(obj, cUser) {
			const { data, user, createdAt } = obj;

			const currentUser = cUser;
			let userName = '';
			if (currentUser?.id && user?.id) {
				userName = currentUser.id === user.id ? 'You' : `${user.firstName} ${user.lastName.charAt(0)}.`;
			}

			const { action = 'interacted with' } = data;

			return {
				date: createdAt,
				logNote: `${userName} ${action}`,
			};
		}
	},
}
</script>

<style lang="scss" scoped>
.timelinePage {
	background-color: white;
	border: 1px solid #E4E7EB;
}
</style>

<style lang="scss">
.ant-timeline-item-last .ant-timeline-item .ant-timeline-item-tail {
	display: none;
}
</style>
